.descricao {
    color: #002e47 !important;
    text-align: left !important;
}

.titulo{
    margin: 1.071rem auto 0  !important;
    width: 6em;
    max-width: 600px;
    color: #054e3b;
    margin: 1.75rem 0 0.875rem;
    text-decoration: none;
    font-family: 'InputMonoCBold', "Times New Roman", serif;
}

#sobre path{

    fill: #ffffff;
}