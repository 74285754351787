
@font-face {
    font-family: 'InputMonoCBold';
    src: url('../../assets/fonts/InputMonoCompressed-Bold.ttf') format('woff2');
  }
  @font-face {
    font-family: 'InputMonoRegular';
    src: url('../../assets/fonts/InputMono-Regular.ttf') format('woff2');
  }
  @font-face {
    font-family: 'InputMonoMedium';
    src: url('../../assets/fonts/InputMono-Medium.ttf') format('woff2');
  }
  @font-face {
    font-family: 'InputMonoCRegular';
    src: url('../../assets/fonts/InputMonoCompressed-Regular.ttf') format('woff2');
  }

.btn {
    font-family: 'InputMonoCRegular';
    border: none;
    background: transparent;
    display: inline-block;
    color: #ffffff;
    padding: 32px;
    position: relative;
    letter-spacing: 1px;
    font-size: 20px;
  }

  
  .btn__circle, .btn__text, .btn__white-circle {
    position: absolute;
  }
  .btn__circle {
    top: 0;
    left: 0;
    height: 100%;
    border-radius: 100%;
    width: 100%;
    box-shadow: 0 0 1px 1px #fff;
    transition: 0.3s linear;
  }
  .btn__white-circle {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    width: 56px;
    height: 56px;
    border-radius: 100%;
    background: #9580b2;
    display: flex;
    transition: 0.3s ease-in-out;
  }
  .btn__white-circle svg {
    width: 24px;
    height: 24px;
    margin: auto;
  }

  .btn .btn__text:hover, .btn__text:active, .btn__text:focus{
    color: #9580b2 !important;
    }

  
      
  .btn__text {
    top: 50%;
    transform: translateY(-50%);
    white-space: nowrap;
    z-index: 2;
    padding: 24px 8px;
    transition: 0.3s linear;
  }
  .btn:hover .btn__circle {
    color: #9580b2 !important;
    transform: scale(0);
  }
  .btn:hover .btn__white-circle {
    transform: translate(-50%, -50%) scale(1);
  }
  .btn:hover .btn__text {
    transform: translate(40px, -50%);
  }

  .titulocust{
      text-transform: uppercase;
  }

  .descricaocust{
      font-weight: 100;
  }


  .Carousel-fullHeightHoverWrapper-97 {
    top: 0 !important;
    height: calc(100% - 0px - 0px) !important;
    }