.bread {
  margin: auto;
  width: 200px;
  position: relative;
  flex-basis: auto;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}

.makeStyles-smallText-121 {
  color: #9f8ebc !important;
  font-size: 80% !important;
  font-weight: 600;
  line-height: 1;
}

@media screen and (max-width: 769px) {
  h2 {
    font-size: 1.5rem;
    line-height: 1.5em;
  }

  .bread {
    margin: auto;
    width: 150px;
    position: relative;
    flex-basis: auto;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

#pangeialogo {
  width: 50%;
}

#Camada_2 {
  width: auto;
  fill: white;
  height: 100px;
}

.makeStyles-card-95 {
  color: #9680b2 !important;
  width: 100%;
  border: 0;
  display: flex;
  position: relative;
  font-size: 1em !important;
  min-width: 0;
  word-wrap: break-word;
  background: rgb(0, 0, 0) !important;
  box-shadow: none !important;
  margin-top: 30px;
  transition: all 300ms linear;
  border-radius: 6px;
  margin-bottom: 30px;
  flex-direction: column;
}

button.slick-arrow.slick-prev,
button.slick-arrow.slick-next {
  opacity: 0 !important;
}

blockquote p {
  font-size: 20px;
}

.makeStyles-quoteAuthor-102 {
  color: white !important;
}

@media screen and (max-width: 769px) {
  .logosReal {
    width: 69%  !important;
  }
  .logosApoio {
    width: 57% !important;
  }
}

.embed-container { position: relative; padding-bottom:56.25%; height:0; overflow: hidden; max-width: 100%; } .embed-container iframe, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%;}


#embedCategoryLink a{
  display: none;
}
