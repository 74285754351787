.descricao {
    color: white !important;
    text-align: left !important;
}

.titulo{
    margin: 1.071rem auto 0  !important;
    max-width: 600px;
    color: #aa96c0;
    text-decoration: none;
    font-family: 'InputMonoCBold', "Times New Roman", serif;
}

.descricao1{
    font-weight: 100;
    text-align: left !important;
    color: white !important;
    font-size: 0.9rem;
}

.icone-pills{

    width: 6em;
}

.makeStyles-tabWrapper-127 {
    font-family: 'InputMonoRegular';
    }

    #agendabrand {
        fill: white;
    
    }

    .bread{

        width: fit-content;
        z-index: 2;
    }

    .titulotexto{
        width: 9em;
    }

    #titulobrasil, #tituloportugal, #titulouruguai, #titulochile, #titulomexico{
        width: 100%;
        max-height: 100px;
    }

    #titulobrasil, #tituloportugal, #titulouruguai, #titulochile, #titulomexico path{
        fill: aa96c0;
    }
