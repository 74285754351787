@font-face {
    font-family: 'InputMonoCBold';
    src: url('../../assets/fonts/InputMonoCompressed-Bold.ttf') format('woff2');
  }
  @font-face {
    font-family: 'InputMonoRegular';
    src: url('../../assets/fonts/InputMono-Regular.ttf') format('woff2');
  }
  @font-face {
    font-family: 'InputMonoMedium';
    src: url('../../assets/fonts/InputMono-Medium.ttf') format('woff2');
  }
  @font-face {
    font-family: 'InputMonoCRegular';
    src: url('../../assets/fonts/InputMonoCompressed-Regular.ttf') format('woff2');
  }

.navlinkscust{

    font-family: "InputMonoCRegular";
}