

.item{
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.item .overlay{
  color: #000;
  width: 100%;
  height: 100%;
  background-color: #fff;
  opacity: 0;
  transition: all 0.5s ease;
  position: absolute;
  top: 0; 
  bottom: 0;
}


/* effect-explode */
.explode .overlay span:nth-child(1){
  position: absolute;
  left: 10%;
  top: 45%;
  font-size: 200px;
  font-weight: bold;
  font-family: coustard;
  transform: translateX(-50%);
  opacity: 0;
}

.explode .overlay span:nth-child(2){
  position: absolute;
  top: 10%;
  left: 10%;
  font-size: 15px;
  opacity: 0;
  font-family: "InputMonoCBold" !important;
  line-height: normal;
}

.explode .overlay span:nth-child(3){
  position: absolute;
  left: 0;
  bottom: 0;
  height: 9px;
  background-color: #aa96c0;
}

.explode .overlay:hover{
  opacity: 0.8;
}

.explode .overlay:hover span:nth-child(1){
  animation: focus 0.4s forwards;
  animation-delay: 0.2s;
}

.explode .overlay:hover span:nth-child(2){
  animation: focus 0.4s forwards;
  animation-delay: 0.4s;
}

.explode .overlay:hover span:nth-child(3){
  animation: block 0.3s forwards;
  animation-delay: 0.15s;
}

@keyframes focus{
  0%   {
    transform:scale(1.4) translateX(-30%);
    filter: blur(10px);
    opacity: 0;
  }
  100% {
    transform:scale(1) translateX(0%);
    filter: blur(0px);
    opacity: 1;
  }
}

@keyframes block{
  0%   {
    width:0;
    filter: blur(5px);
    opacity: 0;
  }
  100% {
    width: 100%;
    filter: blur(0px);
    opacity: 1;
  }
}

.lg-img-wrap{
  background-color: white !important;
}

.lg-group{
  background-color: #aa96c0 !important;
  color: white !important;
}

.lg-sub-html{
  background-color: #aa96c0 !important;
  color: white !important;
}

.lg-thumb{
  background-color: #aa96c0 !important;
  color: white  !important; 
}

.lg-toggle-thumb{
  background-color: #aa96c0 !important;
  color: white !important;
}

.lg-icon{
  color: white !important; 
}

#lg-counter{
  color: white !important; 
}

#lg-actual-size{
  display: none;
}

#lg-zoom-in{
  display: none;
}

#lg-zoom-out{
  display: none;
}

.lg-fullscreen{
  display: none;
}

.lg-download{
  display: none;
}

audio{
  display: none;
}

.loader {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
}

#wave {
	height: 70px;
	width: 70px;
	fill: #fff;
}

@keyframes pulse {
	0% {
		transform: scaleY(1);
		transform-origin: 50% 50%;
	}
	
	50% {
		transform: scaleY(.7);
		transform-origin: 50% 50%;
	}
	
	100% {
		transform: scaleY(1);
		transform-origin: 50% 50%;
	}
}

.lg-prev{
  color: #000 !important;
}

.lg-next{
  color: #000 !important;
}

@media screen and (max-width: 769px) {
  .logo {
    display: none  !important;
  }
  .pad {
    padding: 10px !important;
    padding-top: 10em !important;
  }
  
}

@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .pad {
      padding: 10px !important;
      padding-top: 10em !important;
    }
    .logo {
      display: flex !important;
    }
    #pangeialogo{
      width: 100px;
    }

}

.lg-outer .lg-item:before, .lg-outer .lg-img-wrap:before {
  content: "";
  display: inline-block;
  height: 40%;
  width: 1px;
  margin-right: -1px;
}

@media screen and (max-width: 769px) {
  .youtubePc {
    display: none  !important;
  }
  .youtubeSmart {
    display: flex  !important;
  }
  
}